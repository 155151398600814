/**
 * Initiates a Stripe payment for the specified item.
 *
 * @param {string} environment The appliction environment
 * @param {string} licenseId The ID of a value in the LicenseTypes constant,
 *    indicating which license is being purchased
 */
function initiatePayment(environment, licenseId) {
   if (environment !== 'production') {
      alert('Testing Environment Detected. Use the following credentials:' +
         '\n\ntest@emcoach.org\nCard number - 4242 4242 4242 4242\nExpiration' +
         ' - 2/22\nCVC - 222');
   }

   window.location.href = '/api/v2/stripe/redirect?license=' + licenseId;
}
